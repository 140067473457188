/**
 * Получение ссылки с определенными query параметрами
 * @param {string[]} activeSearchParams параметры, которые нужно оставить в ссылке
 * @return {string | null}
 */

const urlFilteredQuery = (activeSearchParams = []) => {
  if (typeof window === "undefined") {
    return null;
  }
  const { origin, pathname, search } = window.location;
  if (!activeSearchParams?.length) {
    return origin + pathname;
  }
  const searchParams = new URLSearchParams(search);
  const searchParamsKeys = [...searchParams.keys()];
  for (const param of searchParamsKeys) {
    if (!activeSearchParams.includes(param)) {
      searchParams.delete(param);
    }
  }
  return `${origin}${pathname}?${searchParams.toString()}`;
};

export default urlFilteredQuery;
